import React, { useEffect, useState } from 'react'
import { connect, useState as reduxState, useSelector } from 'react-redux'
import Layout from '../components/layout'
import Seo from '../components/seo'
//import Subject from '../components/booking/subject';
import { Dropdown } from 'semantic-ui-react'
import 'semantic-ui-css/components/dropdown.min.css'
import Tutor from '../components/teacher/teacher-component'
import SlotComponent from "../components/teacher/slots"
import { getMethod } from "../helper/api"
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Select from 'react-select'
import { navigate } from 'gatsby'
import { getWindowItem } from '../helper/globals'

const customStyles = {
    menu: (provided, state) => ({
        ...provided,

        borderBottom: '1px dotted pink',
        color: state.selectProps.menuColor,

    }),

    control: (provided, state) => ({
        ...provided,
        height: '28px',
        //padding: '2px',
        border: '1px solid #ccc',
        borderRadius: '18px'
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        height: '28px',


    }),


}

const customMStyles = {
    menuList: (provided, state) => ({
        ...provided,

        borderBottom: '1px dotted pink',
        color: state.selectProps.menuColor,
        height: "200px"

    }),
}

const BookALesson = ({ location }) => {

    let levelOptions = [
        { "key": 1, value: "Beginner", label: "Beginner" },
        { "key": 2, value: "Intermediate", label: "Intermediate" },
        { "key": 3, value: "Advanced", label: "Advanced" }
    ];

    let [days, setDays] = useState(getWindowItem('days') ? JSON.parse(getWindowItem('days', { "Monday": false, "Tuesday": false, "Wednesday": false, "Thursday": false, "Friday": false, "Saturday": false, "Sunday": false })) : { "Monday": false, "Tuesday": false, "Wednesday": false, "Thursday": false, "Friday": false, "Saturday": false, "Sunday": false });
    let [slots, setSlots] = useState([]);
    let [level, setLevel] = useState(levelOptions[getWindowItem('level', 0) - 1] ? levelOptions[getWindowItem('level', 0) - 1] : "");
    const [menuOpen, setMenuOpen] = useState(false);
    let [slotType, setSlotType] = useState(getWindowItem('slotType', "Once") ? getWindowItem('slotType', "Once") : "Once");
    let [duration, setDuration] = useState(getWindowItem('duration', 30) ? parseInt(getWindowItem('duration', 30)) : 30);
    //const bookingDetails = useSelector(state => state.bookingReducer);
    const [value, onChange] = useState(getWindowItem('date', new Date()) ? getWindowItem('date', new Date()) : new Date());
    const [showCalendar, setShowCalendar] = useState(false);
    const [showStartDateCalendar, setShowStartDateCalendar] = useState(false);
    const [showEndDateCalendar, setShowEndDateCalendar] = useState(false);
    const [startDateValue, onChangeStartDate] = useState(getWindowItem('startdate', new Date()) ? getWindowItem('startdate', new Date()) : new Date());
    const [endDateValue, onChangeEndDate] = useState(getWindowItem('enddate', new Date()) ? getWindowItem('enddate', new Date()) : new Date());
    const [subjectSelect, setSubjectSelect] = useState({ });
    const [slotSelected, setSlotSelect] = useState({ });
    const [tutors, setTutors] = useState({ slots: [] });
    const [sdays, setSDays] = useState({ });


    var tutorList = { };
    const subjects = location.state ? location.state.subjects : [];
    let selectedSubject = { };
    let [selectedSubjectValues, setSelectedSubjectValues] = useState(getWindowItem('selectedSubjectValues', "") ? getWindowItem('selectedSubjectValues', { }) : "");
    let [selectedTutor, setSelectedTutor] = useState(null);

    //console.log(sdays);
    //if(days[sdays["day"]]){
    days[sdays["day"]] = sdays["val"];
    //}

    let slotOptions = [
        { "key": 1, value: "09:00", label: "09:00" },
        { "key": 2, value: "09:30", label: "09:30" },
        { "key": 3, value: "10:00", label: "10:00" },
        { "key": 4, value: "10:30", label: "10:30" },
        { "key": 5, value: "11:00", label: "11:00" },
        { "key": 6, value: "11:30", label: "11:30" },
        { "key": 7, value: "12:00", label: "12:00" },
        { "key": 8, value: "12:30", label: "12:30" },
        { "key": 9, value: "13:00", label: "13:00" },
        { "key": 10, value: "13:30", label: "13:30" },
        { "key": 11, value: "14:00", label: "14:00" },
        { "key": 12, value: "14:30", label: "14:30" },
        { "key": 13, value: "15:00", label: "15:00" },
        { "key": 14, value: "15:30", label: "15:30" },
        { "key": 15, value: "16:00", label: "16:00" },
        { "key": 16, value: "16:30", label: "16:30" },
        { "key": 17, value: "17:00", label: "17:00" },
        { "key": 18, value: "17:30", label: "17:30" },
        { "key": 19, value: "18:00", label: "18:00" },
        { "key": 20, value: "18:30", label: "18:30" },
        { "key": 21, value: "19:00", label: "19:00" },
        { "key": 22, value: "19:30", label: "19:40" },
        { "key": 23, value: "20:00", label: "20:00" },
        { "key": 24, value: "20:30", label: "20:30" }
    ]

    let subjectOptions = [];
    let subjectObj = { };
    let faculty = { };

    for (var i = 0; i < subjects.length; i++) {
        subjectOptions.push({ key: subjects[i].id, label: subjects[i].name, value: subjects[i].id })
        Object.assign(subjectObj, { [subjects[i].name]: { key: subjects[i].id, label: subjects[i].name, value: subjects[i].id } })
        if (subjectSelect.value === subjects[i].id || getWindowItem('selectedSubject', -1) == subjects[i].id) {
            //console.log("comes here");
            selectedSubject = subjects[i];
            //subjectSelect = { key: subjects[i].id, label: subjects[i].name, value: subjects[i].id }
        }
    }



    if (!Array.isArray(subjects)) {
        subjects = [];
    }

    if (subjectSelect.value) {
       
        //console.log("subjects",subjects[subjectObj[subjectSelect.label].value]);
        //console.log("trrrr1",subjects);
        //console.log("trrrr3",subjectObj)
        //console.log("trrrr4",subjectSelect)
        faculty = subjects[subjectObj[subjectSelect.label].value].faculties[0];
       
        //selectedSubject = subjectObj[subjectSelect.label].value;
        window.localStorage.setItem('selectedSubject', subjectSelect.value);
        if (subjectObj[subjectSelect.label]) {
            selectedSubjectValues = subjectObj[subjectSelect.label];
            window.localStorage.setItem('selectedSubjectValues', subjectSelect.label);
        }
    }

    if (slotSelected.length === 0) {
        slots = [];
        window.localStorage.setItem('slots', slots);
    }
    else if (slotSelected.length > 0) {
        slots = [];
        for (let i = 0; i < slotSelected.length; i++) {
            slots.push(`${slotSelected[i].value}:00`);
        }
        window.localStorage.setItem('slots', JSON.stringify(slotSelected));
    }

    useEffect(() => {
        window.localStorage.setItem('date', value);
    }, [value]);

    useEffect(() => {
        window.localStorage.setItem('startdate', startDateValue);
    }, [startDateValue]);

    useEffect(() => {
        window.localStorage.setItem('enddate', endDateValue);
    }, [endDateValue]);

    useEffect(() => {
        window.localStorage.setItem('days', JSON.stringify(days));
    }, [sdays['day']]);

    useEffect(() => {
        window.localStorage.setItem('slotType', slotType);
    }, [slotType]);

    useEffect(() => {
        window.localStorage.setItem('duration', duration);
    }, [duration]);

    useEffect(() => {
        window.localStorage.setItem('level', level.key);
    }, [level]);


    useEffect(() => {
        //selectedSubject = window.localStorage.getItem('selectedSubject')?window.localStorage.getItem('selectedSubject'):{};
        if (getWindowItem('selectedSubjectValues', false))
            setSelectedSubjectValues(subjectObj[window.localStorage.getItem('selectedSubjectValues')]);
        setSlotType(getWindowItem('slotType', "Once"));
        onChange(new Date(getWindowItem('date', new Date())))
        onChangeStartDate(new Date(getWindowItem('startdate', new Date())))
        onChangeEndDate(new Date(getWindowItem('enddate', new Date())))
        if (getWindowItem('slots', false))
            setSlotSelect(JSON.parse(window.localStorage.getItem('slots')));

        if (getWindowItem('days', days))
            setDays(JSON.parse(window.localStorage.getItem('days')));

        window.localStorage.removeItem('cart');

        //if(levelOptions[window.localStorage.getItem('level')-1])
        //setLevel(levelOptions[window.localStorage.getItem('level')-1]);

    }, []);



    useEffect(() => {

        async function getSlots() {

            let cond = { };

            let dayKeys = Object.keys(days);
            let selectedDays = []
            for (let i = 0; i < dayKeys.length; i++) {
                if (days[dayKeys[i]]) selectedDays.push(dayKeys[i]);
            }

            if (slotType == 'Recurring') {
                cond = {
                    date_gte: new Date(startDateValue.valueOf() + (1000 * 60 * 60 * ((-1 * new Date().getTimezoneOffset()) / 60))).toISOString().slice(0, 10),
                    date_lte: new Date(endDateValue.valueOf() + (1000 * 60 * 60 * ((-1 * new Date().getTimezoneOffset()) / 60))).toISOString().slice(0, 10),
                    id: selectedSubjectValues.value,
                    slots: slots,
                    days: selectedDays.join(","),
                    duration: duration,
                    level: level.value
                }
            } else {
                cond = {
                    date: new Date(value.valueOf() + (1000 * 60 * 60 * ((-1 * new Date().getTimezoneOffset()) / 60))).toISOString().slice(0, 10),
                    id: selectedSubjectValues.value,
                    duration: duration,
                    slots: slots,
                    level: level.value
                };
            }

            let res = await getMethod('/tutors', { }, cond);
            for (let i = 0; i < res.data.slots.length; i++) {
                Object.assign(tutorList, { [res.data.slots[i].faculty.id]: res.data.slots[i].faculty });
            }

            await setTutors(res.data);

            if (!getWindowItem('selectedTeacher', false) && res.data.slots.length > 0) {
                window.localStorage.setItem('selectedTeacher', res.data.slots[0].faculty.id);
                setSelectedTutor(res.data.slots[0].faculty)
            } else if (getWindowItem('selectedTeacher', false)) {
                setSelectedTutor(tutorList[window.localStorage.getItem('selectedTeacher')])
            }

            if (res.data.slots.length === 0 && getWindowItem('selectedTeacher', false)) {
                window.localStorage.removeItem('selectedTeacher');
                setSelectedTutor("");
            }
        }

        if (selectedSubjectValues.value && slots.length > 0) getSlots();

    }, [selectedSubjectValues.value, slots.length, value]);

    function confirm() {
        //console.log(value.toDateString());
        let dayKeys = Object.keys(days);
        let selectedDays = []
        for (let i = 0; i < dayKeys.length; i++) {
            if (days[dayKeys[i]]) selectedDays.push(dayKeys[i]);
        }
        navigate("/booking-checkout", { state: { faculty: selectedTutor, level: level.value, subject: selectedSubject, slotType: slotType, duration: duration, slot: { date: value, time: slots, duration: duration, startDate: startDateValue, endDate: endDateValue, days: selectedDays.join(",") } } })
    }

    //let selectedLevelValue = levelOptions[window.localStorage.getItem('level')-1];

    //let selectedTutor = tutorList[selectedTutorId];
    //selectedTutor = {};

    //console.log(new Date().getTimezoneOffset());

    return (
        <Layout title="Book-a-Lesson" >
            <Seo title='Home' />
            {/* <h5 style={{ paddingLeft: '30px' }}>Choose a Subject</h5> */}
            <div style={{ minHeight: "600px", paddingLeft: "20px" }}>
                <div className="container animated fadeInLeft" style={{ zIndex: 1000 }}>
                    <div className="row m-sm" style={{ paddingLeft: "0px" }}>
                        <div style={{ width: "55%", float: "left", marginLeft: "-10px" }}>

                            <Select

                                placeholder='Subject'
                                isSearchable={false}
                                fluid
                                options={subjectOptions}
                                onChange={setSubjectSelect}
                                style={{ "borderRadius": "15px", "border": "1px solid #CCC", height: "20px" }}
                                styles={customStyles}
                                value={selectedSubjectValues}

                            />

                        </div>
                        <div style={{ width: "10%" }}></div>
                        <div style={{ width: "40%", float: "left", textAlign: 'left', marginLeft: "-10px" }}>
                            <Select
                                placeholder='Level'
                                fluid
                                isSearchable={false}
                                options={levelOptions}
                                style={{ "borderRadius": "15px", "border": "1px solid #CCC" }}
                                styles={customStyles}
                                onChange={setLevel}
                                value={level}
                            />
                        </div>
                    </div>
                </div>

                {selectedSubjectValues.value && (
                    <>
                        <p style={{ "fontWeight": "500", paddingLeft: "10px" }}>Schedule</p>
                        <div className={slotType === 'Once' ? "btn btn-sm btn-blue" : "btn btn-sm btn-white"} onClick={() => { setSlotType("Once"); }}>Once</div>
                        <div className={slotType === 'Recurring' ? "btn btn-sm btn-blue" : "btn btn-sm btn-white"} onClick={() => { setSlotType("Recurring"); }}>Recurring</div>
                        {slotType === 'Once' && (<div className="row m-sm" >
                            <p style={{ "fontWeight": "500", marginTop: "5px" }}>Select Date</p>
                            <input type="text" value={value.toLocaleDateString("default", { dateStyle: "medium" })} readOnly="read-only" onClick={() => setShowCalendar(!showCalendar)} style={{ marginLeft: "15px", textAlign: 'center', fontSize: "20", border: "1px solid #CCC" }} />
                        </div>)}
                        {showCalendar && (
                            <div style={{ width: '80%', margin: 'auto', padding: '10px' }}>
                                <Calendar
                                    onChange={(event) => { onChange(event); setShowCalendar(false) }}
                                    value={value}
                                    minDate={new Date()}
                                    maxDate={new Date(Date.now() + (1000 * 60 * 60 * 24 * 30))}
                                />
                            </div>)}

                        {slotType === 'Recurring' && (<div className="row m-sm" >
                            <p style={{ "fontWeight": "500", marginTop: "5px" }}>Start Date</p>
                            <input type="text" value={startDateValue.toLocaleDateString("default", { dateStyle: "medium" })} readOnly="read-only" onClick={() => setShowStartDateCalendar(!showStartDateCalendar)} style={{ marginLeft: "15px", textAlign: 'center', fontSize: "20", border: "1px solid #CCC" }} />
                        </div>)}
                        {showStartDateCalendar && (
                            <div style={{ width: '80%', margin: 'auto', padding: '10px' }}>
                                <Calendar
                                    onChange={(event) => { onChangeStartDate(event); setShowStartDateCalendar(false) }}
                                    value={startDateValue}
                                    minDate={new Date()}
                                    maxDate={new Date(Date.now() + (1000 * 60 * 60 * 24 * 30))}
                                />
                            </div>)}
                        {slotType === 'Recurring' && (<div className="row m-sm" >
                            <p style={{ "fontWeight": "500", marginTop: "5px" }}>End Date  </p>
                            <input type="text" value={endDateValue.toLocaleDateString("default", { dateStyle: "medium" })} readOnly="read-only" onClick={() => setShowEndDateCalendar(!showEndDateCalendar)} style={{ marginLeft: "20px", textAlign: 'center', fontSize: "20", border: "1px solid #CCC" }} />
                        </div>)}
                        {showEndDateCalendar && (
                            <div style={{ width: '80%', margin: 'auto', padding: '10px' }}>
                                <Calendar
                                    onChange={(event) => { onChangeEndDate(event); setShowEndDateCalendar(false) }}
                                    value={endDateValue}
                                    minDate={new Date()}
                                    maxDate={new Date(Date.now() + (1000 * 60 * 60 * 24 * 30))}
                                />
                            </div>)}
                        {slotType === 'Recurring' && (<div className="row m-sm" >
                            <p style={{ "fontWeight": "500", paddingLeft: "0px", width: "100%" }}>Select Days</p>
                            <div className={days["Monday"] ? "btn btn-day btn-blue" : "btn btn-day  btn-blue hidden"} onClick={() => setSDays({ "day": "Monday", "val": !days["Monday"] })}>M</div>
                            <div className={days["Tuesday"] ? "btn btn-day btn-blue" : "btn btn-day  btn-blue hidden"} onClick={() => setSDays({ "day": "Tuesday", "val": !days["Tuesday"] })}>T</div>
                            <div className={days["Wednesday"] ? "btn btn-day btn-blue" : "btn btn-day  btn-blue hidden"} onClick={() => setSDays({ "day": "Wednesday", "val": !days["Wednesday"] })}>W</div>
                            <div className={days["Thursday"] ? "btn btn-day btn-blue" : "btn btn-day  btn-blue hidden"} onClick={() => setSDays({ "day": "Thursday", "val": !days["Thursday"] })}>T</div>
                            <div className={days["Friday"] ? "btn btn-day btn-blue" : "btn btn-day  btn-blue hidden"} onClick={() => setSDays({ "day": "Friday", "val": !days["Friday"] })}>F</div>
                            <div className={days["Saturday"] ? "btn btn-day btn-blue" : "btn btn-day  btn-blue hidden"} onClick={() => setSDays({ "day": "Saturday", "val": !days["Saturday"] })}>S</div>
                            <div className={days["Sunday"] ? "btn btn-day btn-blue" : "btn btn-day  btn-blue hidden"} onClick={() => setSDays({ "day": "Sunday", "val": !days["Sunday"] })}>S</div>

                        </div>)}

                        <div className="row m-sm" >
                            <p style={{ "fontWeight": "500" }}>Class Duration</p>
                        </div>
                        <div className={duration === 30 ? "btn btn-sm btn-blue" : "btn btn-sm btn-white"} onClick={() => { setDuration(30); }}>30 mins</div>
                        <div className={duration === 60 ? "btn btn-sm btn-blue" : "btn btn-sm btn-white"} onClick={() => { setDuration(60); }}>1 hr</div>

                        <div className="row m-sm" >
                            <p style={{ paddingTop: 10, width: "23%", float: "left", textAlign: 'left', "fontWeight": "500", marginTop: "5px" }}>Select Slots</p>
                            {/* <div style={{ width: "55%", float: "left", textAlign: 'center', marginLeft: "0px" }}></div> */}
                            <div style={{ width: "55%", float: "left", textAlign: 'center', marginLeft: "15px", zIndex: 1000 }}>
                                <Select
                                    placeholder='Select Slots'
                                    isClearable={true}
                                    isMulti={true}
                                    options={slotOptions}
                                    onChange={setSlotSelect}
                                    style={{ "borderRadius": "15px", "border": "1px solid #CCC", textAlign: "center" }}
                                    styles={customMStyles}
                                    onMenuOpen={() => setMenuOpen(true)}
                                    onMenuClose={() => setMenuOpen(false)}
                                    value={getWindowItem('slots', false) ? JSON.parse(window.localStorage.getItem('slots')) : ""}
                                />
                            </div>
                        </div>
                    </>)}

                {tutors.slots.length > 0 && slots.length > 0 && selectedTutor && (
                    <>
                        <h6 style={{ paddingLeft: '30px' }}>Usual Tutor</h6>
                        <Tutor teacher={selectedTutor} selectedSubject={selectedSubject} tutors={tutors.slots}></Tutor>
                    </>
                )}

                <div className="row m-sm" >
                    <div className={slots.length > 0 && selectedSubject && getWindowItem("selectedTeacher", false) ? "btn btn-lg btn-blue" : "btn btn-lg  btn-blue hidden"} onClick={() => { if (slots.length > 0 && selectedSubject) confirm(); }}>Next</div>
                </div>
            </div>
        </Layout>
    )
}

export default BookALesson
// export default connect(
//     state => ({ bookingObj: state.bookingReducer }),
//     null
// )(BookALesson)
